// @mui
import { Stack, Button, Typography } from "@mui/material";
// hooks
import useAuth from "../../../hooks/useAuth";
// routes
import { PATH_CONTACT } from "../../../routes/paths";
// assets
import { DocIllustration } from "../../../assets";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <Stack
      spacing={3}
      sx={{ px: 5, py: 3, width: 1, textAlign: "center", display: "block" }}
    >
      {/* <DocIllustration sx={{ width: 1 }} /> */}

      <div>
        {/* <Typography gutterBottom variant="subtitle1">
          {t("params.hi")} {user?.username} !
        </Typography> */}
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {t("params.need_help")}
        </Typography>
      </div>

      <Button
        href={PATH_CONTACT}
        target="_blank"
        rel="noopener"
        variant="contained"
      >
        {t("params.contactus")}
      </Button>
    </Stack>
  );
}
