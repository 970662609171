import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isBtnLoading: false,
  error: null,
  users: [],
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    startBtnLoading(state) {
      state.isBtnLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isBtnLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.isBtnLoading = false;
      state.users = action.payload;
    },

    handleUpdateUser(state, action) {
      const user = action.payload;
      const userIndex = state.users.findIndex((u) => u.id === user.id);
      if (userIndex !== -1) {
        state.isLoading = false;
        state.isBtnLoading = false;
        state.users[userIndex] = user;
      }
    },
  },
});

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

export function getUsers(name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/accounts/users?name=${name}`);
      dispatch(slice.actions.getUsersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getUserProfile(username) {
  const response = await axios.get(
    `/api/accounts/user-profile?username=${username}`
  );
  return response.data;
}

export function inviteFriend(friendId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/accounts/send-friend", {
        friendId,
      });
      dispatch(slice.actions.handleUpdateUser(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addFriend(friendId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/accounts/add-friend", {
        friendId,
      });
      dispatch(slice.actions.handleUpdateUser(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeFriend(friendId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/accounts/remove-friend", {
        friendId,
      });
      dispatch(slice.actions.handleUpdateUser(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
