import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

RHFDateField.propTypes = {
  name: PropTypes.string,
};

export default function RHFDateField({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <DatePicker
          value={value ? new Date(value) : null}
          onChange={(date) => onChange(date?.toISOString() ?? null)}
          format="MM-dd-yyyy"
          fullWidth
          error={!!error}
          helperText={error?.message}
          {...other}
          slotProps={{ textField: { variant: "outlined" } }}
        />
      )}
    />
  );
}
