// routes
import { PATH_AUTH, PATH_CONTACT, PATH_PAGE } from "../../routes/paths";
// components
import { PATH_AFTER_LOGIN } from "../../config";
// components
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: "Akey",
    icon: <Iconify icon={"eva:home-fill"} {...ICON_SIZE} />,
    path: "/",
  },
  // {
  //   title: "À propos de nous",
  //   icon: <Iconify icon={"mdi:about-circle-outline"} {...ICON_SIZE} />,
  //   path: PATH_PAGE.about,
  // },
  // {
  //   title: "Contact",
  //   icon: <Iconify icon={"fluent-mdl2:edit-contact"} {...ICON_SIZE} />,
  //   path: PATH_PAGE.contact,
  // },
  // {
  //   title: "FAQs",
  //   icon: <Iconify icon={"wpf:faq"} {...ICON_SIZE} />,
  //   path: PATH_PAGE.faqs,
  // },
];

export default menuConfig;
