import { memo } from "react";
// @mui
import { styled } from "@mui/material/styles";
import { Container, AppBar } from "@mui/material";
// config
import { HEADER } from "../../../config";
import useAuth from "../../../hooks/useAuth";
// components
import SvgIconStyle from "../../../components/SvgIconStyle";
import { NavSectionHorizontal } from "../../../components/nav-section";
import BankNavConfig from "./BankNavConfig";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------
const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);


const RootStyle = styled(AppBar)(({ theme }) => ({
  transition: theme.transitions.create("top", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  width: "100%",
  position: "fixed",
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(1, 0),
  boxShadow: theme.customShadows.z8,
  top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

function NavbarHorizontal() {
  const { t } = useTranslation();

  const { user } = useAuth();

  return (
    <RootStyle>
      <Container maxWidth={false}>
        <NavSectionHorizontal
          permissions_list={user?.user_permissions_list}
          navConfig={BankNavConfig()}
        />
      </Container>
    </RootStyle>
  );
}

export default memo(NavbarHorizontal);
