import PropTypes from "prop-types";
import { memo } from "react";
// @mui
import { Stack } from "@mui/material";
//
import { NavListRoot } from "./NavList";
import handlePermission from "../../../utils/handlePerm";

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

NavSectionHorizontal.propTypes = {
  navConfig: PropTypes.array,
  permissions_list: PropTypes.array,
};

function NavSectionHorizontal({ navConfig, permissions_list }) {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{ bgcolor: "background.neutral", borderRadius: 1, px: 0.5 }}
    >
      <Stack direction="row" sx={{ ...hideScrollbar, py: 1 }}>
        {navConfig.map((group) =>
          group?.perm ? (
            handlePermission(permissions_list, group?.perm) && (
              <Stack key={group.subheader} direction="row" flexShrink={0}>
                {group.items.map((list) =>
                  list?.perm ? (
                    handlePermission(permissions_list, list?.perm) && (
                      <NavListRoot key={list.title} list={list} />
                    )
                  ) : (
                    <NavListRoot key={list.title} list={list} />
                  )
                )}
              </Stack>
            )
          ) : (
            <Stack key={group.subheader} direction="row" flexShrink={0}>
              {group.items.map((list) =>
                list?.perm ? (
                  handlePermission(permissions_list, list?.perm) && (
                    <NavListRoot key={list.title} list={list} />
                  )
                ) : (
                  <NavListRoot key={list.title} list={list} />
                )
              )}
            </Stack>
          )
        )}
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
