// hooks
import useAuth from "../hooks/useAuth";
// utils
import createAvatar from "../utils/createAvatar";
//
import Avatar from "./Avatar";

// ----------------------------------------------------------------------

export default function MyAvatar({ photo, username, ...other }) {
  return (
    <Avatar
      src={photo}
      alt={username}
      color={photo ? "primary" : createAvatar(username).color}
      {...other}
    >
      {createAvatar(username).name}
    </Avatar>
  );
}
