import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  quizpricings: [],
  isUserPlay: false,
  isUserGameOver: false,
  gameSession: null,
  selectedFriend: 1,
};

const slice = createSlice({
  name: "quizpvp",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SELECT FRIEND
    selectFriend(state, action) {
      const friendId = action.payload;
      state.selectedFriend = friendId;
    },

    // CLOSE GAME SESSION
    closeGameSession(state) {
      state.isUserPlay = false;
      state.isUserGameOver = false;
      state.gameSession = null;
    },

    // OVER GAME SESSION
    overGameSession(state, action) {
      state.isUserPlay = false;
      state.isUserGameOver = true;
      state.gameSession = action.payload;
    },

    // PAY
    payPlanSuccess(state, action) {
      state.error = null;
      state.isLoading = false;
      state.isUserPlay = true;
      state.gameSession = action.payload;
    },

    // UPDATE GAME
    updateGameSession(state, action) {
      state.error = null;
      state.gameSession = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  selectQuizpricing,
  closeGameSession,
  overGameSession,
  updateGameSession,
  payPlanSuccess
} = slice.actions;

// ----------------------------------------------------------------------

