import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  tickets: { count: 0, data: [], num_pages: 1, page: 1, page_size: "5" },
};

const slice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TICKETS
    getTicketsSuccess(state, action) {
      state.isLoading = false;
      state.tickets = action.payload;
    },

    // DELETE TICKET
    deleteTicketSuccess(state, action) {
      const deletedTicketId = action.payload;
      state.tickets.data = state.tickets.data.filter(
        (ticket) => ticket.id !== deletedTicketId
      );
      state.isLoading = false;
    },

    // DELETE TICKETS
    deleteTicketsSuccess(state, action) {
      const deletedTicketIds = action.payload;
      state.tickets.data = state.tickets.data.filter(
        (ticket) => !deletedTicketIds.includes(ticket.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteTicketSuccess, deleteTicketsSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getTickets(
  page = 1,
  rowsPerPage = 5,
  filterStartDate,
  filterEndDate,
  filterTirage,
  filterSuccursale,
  filterUser
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/v1/dashboard/tickets?page=${page}&page_size=${rowsPerPage}&start_date=${filterStartDate}&end_date=${filterEndDate}&tirages=${filterTirage}&succursale=${filterSuccursale}&user=${filterUser}`
      );
      dispatch(slice.actions.getTicketsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getTicket(keyId) {
  const response = await axios.get(`/api/v1/dashboard/ticket-id?keyId=${keyId}`);
  return response.data;
}

export async function deleteTicket(keyId) {
  const response = await axios.delete(`/api/v1/dashboard/tickets/${keyId}/`);
  return response.data;
}

export async function deleteTickets(keyIds) {
  const response = await axios.delete(`/api/v1/dashboard/ticket-id`, {
    data: { keyIds },
  });
  return response.data;
}
