import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import mailReducer from "./slices/mail";
import chatReducer from "./slices/chat";
import productReducer from "./slices/product";
import calendarReducer from "./slices/calendar";
import kanbanReducer from "./slices/kanban";
import quizpricingReducer from "./slices/quizpricing";
import quizpvpReducer from "./slices/quizpvp";
import exchangerateReducer from "./slices/exchangerate";
import generaleReducer from "./slices/generale";
import annonceReducer from "./slices/annonces";
import usersReducer from "./slices/users";
import friendsReducer from "./slices/friends";
import notificationsReducer from "./slices/notifications";
import vendorReducer from "./slices/vendor";
import customerReducer from "./slices/customer";
import feeReducer from "./slices/fee";
import supervisorReducer from "./slices/supervisor";
import succursaleReducer from "./slices/succursale";
import primeReducer from "./slices/prime";
import optionReducer from "./slices/option";
import tirageReducer from "./slices/tirage";
import ticketReducer from "./slices/ticket";
import ticketwinReducer from "./slices/ticketwin";
import limitgameReducer from "./slices/limitgame";
import limitgametirageReducer from "./slices/limitgametirage";
import lotgagnantReducer from "./slices/lotgagnant";
import paymentReducer from "./slices/payment";
import statistiqueReducer from "./slices/statistique";
import limitbouleReducer from "./slices/limitboule";
import limitbouletirageReducer from "./slices/limitbouletirage";
import limitticketReducer from "./slices/limitticket";
import limitventeReducer from "./slices/limitvente";
import limitquantitybouleReducer from "./slices/limitquantityboule";
import limittransactionReducer from "./slices/limittransaction";
import reportReducer from "./slices/report";
import reportuserReducer from "./slices/reportuser";
import mariageReducer from "./slices/mariage";
import countryReducer from "./slices/countries";
import questionReducer from "./slices/questions";
import categoryReducer from "./slices/categories";
import bouleblockReducer from "./slices/bouleblock";
import primetirageReducer from "./slices/primetirage";
import primeuserReducer from "./slices/primeuser";
import primesuccursaleReducer from "./slices/primesuccursale";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const rootReducer = combineReducers({
  primetirage: primetirageReducer,
  primeuser: primeuserReducer,
  primesuccursale: primesuccursaleReducer,
  bouleblock: bouleblockReducer,
  statistique: statistiqueReducer,
  payment: paymentReducer,
  lotgagnant: lotgagnantReducer,
  report: reportReducer,
  reportuser: reportuserReducer,
  mariage: mariageReducer,
  ticket: ticketReducer,
  ticketwin: ticketwinReducer,
  limitgame: limitgameReducer,
  limitgametirage: limitgametirageReducer,
  limitboule: limitbouleReducer,
  limitbouletirage: limitbouletirageReducer,
  limitticket: limitticketReducer,
  limitvente: limitventeReducer,
  limitquantityboule: limitquantitybouleReducer,
  tirage: tirageReducer,
  option: optionReducer,
  prime: primeReducer,
  generale: generaleReducer,
  exchangerate: exchangerateReducer,
  supervisor: supervisorReducer,
  succursale: succursaleReducer,
  vendor: vendorReducer,
  customer: customerReducer,
  fee: feeReducer,
  quizpricing: quizpricingReducer,
  quizpvp: quizpvpReducer,
  limit_transaction: limittransactionReducer,
  annonce: annonceReducer,
  country: countryReducer,
  questions: questionReducer,
  categories: categoryReducer,
  users: usersReducer,
  friends: friendsReducer,
  notifications: notificationsReducer,
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
