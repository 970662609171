import PropTypes from "prop-types";
// @mui
import { Box } from "@mui/material";
// components
import { IconButtonAnimate } from "../../../components/animate";
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

CollapseButton.propTypes = {
  collapseClick: PropTypes.bool,
  onToggleCollapse: PropTypes.func,
};

export default function CollapseButton({ onToggleCollapse, collapseClick }) {
  return (
    <IconButtonAnimate onClick={onToggleCollapse}>
      <Box
        sx={{
          lineHeight: 0,
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shorter,
            }),
        }}
      >
        <Iconify
          icon={collapseClick ? "bx:menu-alt-left" : "bx:menu-alt-right"}
          width={24}
          height={24}
          sx={{color: 'text.primary'}}
        />
      </Box>
    </IconButtonAnimate>
  );
}
