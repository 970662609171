import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  bouleblocks: {
    count: 0,
    data: [],
    num_pages: 1,
    page: 1,
    page_size: "5",
  },
};

const slice = createSlice({
  name: "bouleblock",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOULE BLOCK
    getBouleBlockSuccess(state, action) {
      state.isLoading = false;
      state.bouleblocks = action.payload;
    },

    // ADD BOULE BLOCK
    addBouleBlockSuccess(state, action) {
      const bouleblock = action.payload;
      state.bouleblocks.data.push(bouleblock);
      state.bouleblocks.count += 1;
    },

    // UPDATE BOULE BLOCKS
    updateBouleBlockSuccess(state, action) {
      const bouleblock = action.payload;
      if (state.bouleblocks && state.bouleblocks.data) {
        const bouleblockIndex = state.bouleblocks?.data?.findIndex(
          (obj) => obj.id === bouleblock.id
        );
        if (bouleblockIndex !== -1) {
          state.isLoading = false;
          state.bouleblocks.data[bouleblockIndex] = bouleblock;
        }
      } else {
        state.bouleblocks = { data: [] };
        state.bouleblocks.data.push(bouleblock);
      }
    },

    // DELETE BOULE BLOCK
    deleteBouleBlockSuccess(state, action) {
      const deletedBouleBlockId = action.payload;
      state.bouleblocks.data = state.bouleblocks.data.filter(
        (bouleblock) => bouleblock.id !== deletedBouleBlockId
      );
      state.isLoading = false;
    },

    // DELETE BOULE BLOCKS
    deleteBouleBlocksSuccess(state, action) {
      const deletedBouleBlockIds = action.payload;
      state.bouleblocks.data = state.bouleblocks.data.filter(
        (bouleblock) => !deletedBouleBlockIds.includes(bouleblock.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addBouleBlockSuccess,
  updateBouleBlockSuccess,
  deleteBouleBlockSuccess,
  deleteBouleBlocksSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getBouleBlocks(page = 1, rowsPerPage = 5) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/v1/dashboard/boule-block?page=${page}&page_size=${rowsPerPage}`
      );
      dispatch(slice.actions.getBouleBlockSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getBouleBlock(keyId) {
  const response = await axios.get(
    `/api/v1/dashboard/boule-block-id?keyId=${keyId}`
  );
  return response.data;
}

export async function addBouleBlock(data) {
  const response = await axios.post(`/api/v1/dashboard/boule-block`, data);
  return response.data;
}

export async function updateBouleBlock(data) {
  const response = await axios.put(`/api/v1/dashboard/boule-block`, data);
  return response.data;
}

export async function deleteBouleBlock(keyId) {
  const response = await axios.delete(`/api/v1/dashboard/boule-block/${keyId}/`);
  return response.data;
}

export async function deleteBouleBlocks(keyIds) {
  const response = await axios.delete(`/api/v1/dashboard/boule-block-id`, {
    data: { keyIds },
  });
  return response.data;
}
