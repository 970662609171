// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
};

export const PATH_PAGE = {
  PolitiqueConf: "politique-confidentialite",
  TermesConditions: "termes-conditions",
  ReglesJeu: "regles-jeu",
  PolitiqueRemboursement: "politique-remboursement",
  PolitiqueModeration: "politique-moderation",
  ConditionsAffiliation: "conditions-affiliation",
  PolitiqueSecurite: "politique-securite",
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/home"),
  },
  configuration_management: {
    root: path(ROOTS_DASHBOARD, "/configuration"),
  },
  configuration: {
    root: path(ROOTS_DASHBOARD, "/configuration-transaction"),
    exchange_rate: path(
      ROOTS_DASHBOARD,
      "/configuration-transaction/exchange-rate"
    ),
    new_exchange_rate: path(
      ROOTS_DASHBOARD,
      "/configuration-transaction/exchange-rate/new"
    ),
    edit_rate: (id) =>
      path(
        ROOTS_DASHBOARD,
        `/configuration-transaction/exchange-rate/${id}/edit`
      ),
    fee: path(ROOTS_DASHBOARD, "/configuration-transaction/fee"),
    new_fee: path(ROOTS_DASHBOARD, "/configuration-transaction/fee/new"),
    edit_fee: (id) =>
      path(ROOTS_DASHBOARD, `/configuration-transaction/fee/${id}/edit`),
  },
  security_and_limit: {
    root: path(ROOTS_DASHBOARD, "/security-and-limit"),
    limit_transaction_generale: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/limit-transaction-generale"
    ),
    new_limit_transaction_generale: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/limit-transaction-generale/new"
    ),
    edit_limit_transaction_generale: (id) =>
      path(
        ROOTS_DASHBOARD,
        `/security-and-limit/limit-transaction-generale/${id}/edit`
      ),

    limit_game: path(ROOTS_DASHBOARD, "/security-and-limit/limit-game"),
    new_limit_game: path(ROOTS_DASHBOARD, "/security-and-limit/limit-game/new"),
    edit_limit_game: (id) =>
      path(ROOTS_DASHBOARD, `/security-and-limit/limit-game/${id}/edit`),

    limit_game_tirage: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/limit-game-tirage"
    ),
    new_limit_game_tirage: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/limit-game-tirage/new"
    ),
    edit_limit_game_tirage: (id) =>
      path(ROOTS_DASHBOARD, `/security-and-limit/limit-game-tirage/${id}/edit`),

    limit_boule: path(ROOTS_DASHBOARD, "/security-and-limit/limit-boule"),
    new_limit_boule: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/limit-boule/new"
    ),
    edit_limit_boule: (id) =>
      path(ROOTS_DASHBOARD, `/security-and-limit/limit-boule/${id}/edit`),

    bouleblock: path(ROOTS_DASHBOARD, "/security-and-limit/boule-block"),
    new_bouleblock: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/boule-block/new"
    ),
    edit_bouleblock: (id) =>
      path(ROOTS_DASHBOARD, `/security-and-limit/boule-block/${id}/edit`),

    limit_boule_tirage: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/limit-boule-tirage"
    ),
    new_limit_boule_tirage: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/limit-boule-tirage/new"
    ),
    edit_limit_boule_tirage: (id) =>
      path(
        ROOTS_DASHBOARD,
        `/security-and-limit/limit-boule-tirage/${id}/edit`
      ),

    limit_ticket: path(ROOTS_DASHBOARD, "/security-and-limit/limit-ticket"),
    new_limit_ticket: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/limit-ticket/new"
    ),
    edit_limit_ticket: (id) =>
      path(ROOTS_DASHBOARD, `/security-and-limit/limit-ticket/${id}/edit`),

    limit_vente: path(ROOTS_DASHBOARD, "/security-and-limit/limit-vente"),
    new_limit_vente: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/limit-vente/new"
    ),
    edit_limit_vente: (id) =>
      path(ROOTS_DASHBOARD, `/security-and-limit/limit-vente/${id}/edit`),

    limit_quantityboule: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/limit-quantity-boule"
    ),
    new_limit_quantityboule: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/limit-quantity-boule/new"
    ),
    edit_limit_quantityboule: (id) =>
      path(
        ROOTS_DASHBOARD,
        `/security-and-limit/limit-quantity-boule/${id}/edit`
      ),

    prime_tirage: path(ROOTS_DASHBOARD, "/security-and-limit/prime-tirage"),
    new_prime_tirage: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/prime-tirage/new"
    ),
    edit_prime_tirage: (id) =>
      path(ROOTS_DASHBOARD, `/security-and-limit/prime-tirage/${id}/edit`),

    prime_user: path(ROOTS_DASHBOARD, "/security-and-limit/prime-user"),
    new_prime_user: path(ROOTS_DASHBOARD, "/security-and-limit/prime-user/new"),
    edit_prime_user: (id) =>
      path(ROOTS_DASHBOARD, `/security-and-limit/prime-user/${id}/edit`),

    prime_succursale: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/prime-succursale"
    ),
    new_prime_succursale: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/prime-succursale/new"
    ),
    edit_prime_succursale: (id) =>
      path(ROOTS_DASHBOARD, `/security-and-limit/prime-succursale/${id}/edit`),

    lotgagnant: path(ROOTS_DASHBOARD, "/security-and-limit/lot-gagnant"),
    new_lotgagnant: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/lot-gagnant/new"
    ),
    edit_lotgagnant: (id) =>
      path(ROOTS_DASHBOARD, `/security-and-limit/lot-gagnant/${id}/edit`),

    statistique: path(ROOTS_DASHBOARD, "/security-and-limit/statistiques"),
  },
  vendor: {
    root: path(ROOTS_DASHBOARD, "/vendor"),
    list: path(ROOTS_DASHBOARD, "/vendor/list"),
    add: path(ROOTS_DASHBOARD, "/vendor/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/vendor/${id}/edit`),
  },
  succursale: {
    root: path(ROOTS_DASHBOARD, "/succursale"),
    list: path(ROOTS_DASHBOARD, "/succursale/list"),
    add: path(ROOTS_DASHBOARD, "/succursale/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/succursale/${id}/edit`),
  },
  supervisor: {
    root: path(ROOTS_DASHBOARD, "/master-vendor"),
    list: path(ROOTS_DASHBOARD, "/master-vendor/list"),
    add: path(ROOTS_DASHBOARD, "/master-vendor/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/master-vendor/${id}/edit`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
  },
  ticket: {
    root: path(ROOTS_DASHBOARD, "/ticket"),
    sale: path(ROOTS_DASHBOARD, "/ticket/sale"),
    win: path(ROOTS_DASHBOARD, "/ticket/win"),
    view: (id) => path(ROOTS_DASHBOARD, `/ticket/sale/${id}`),
    view_win: (id) => path(ROOTS_DASHBOARD, `/ticket/win/${id}`),
  },
  report: {
    root: path(ROOTS_DASHBOARD, "/report"),
    sale: path(ROOTS_DASHBOARD, "/report/sale"),
    user: path(ROOTS_DASHBOARD, "/report/user"),
  },
  transaction: {
    root: path(ROOTS_DASHBOARD, "/transaction"),
    list: path(ROOTS_DASHBOARD, "/transaction/list"),
    add: path(ROOTS_DASHBOARD, "/transaction/new"),
  },
};

export const PATH_CONTACT = "/#/contact-us";
